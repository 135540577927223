var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "modalhistory",
    attrs: {
      "id": "modal-data-history",
      "no-enforce-focus": true,
      "ok-only": "",
      "ok-variant": "secondary",
      "ok-title": "Close",
      "centered": "",
      "size": "xl",
      "title": "❗️ Data Histori ❗️"
    }
  }, [_c('b-card', {
    attrs: {
      "body-class": "p-0"
    }
  }, [_vm.fetchLoading ? _c('b-progress', {
    key: "info",
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": "100",
      "variant": "info"
    }
  }) : _vm._e(), _c('b-table', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary",
      modifiers: {
        "hover": true,
        "v-primary": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: _vm.disableEdit == false || _vm.disableEdit == null ? 'Untuk Mengubah Data, Klik 2x Pada Kolomnya' : '',
      expression: "\n          disableEdit == false || disableEdit == null\n            ? 'Untuk Mengubah Data, Klik 2x Pada Kolomnya'\n            : ''\n        ",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    ref: _vm.refSpecify || 'YoTable',
    staticClass: "m-0",
    staticStyle: {},
    attrs: {
      "id": "table-transition",
      "fields": _vm.customedFields,
      "no-border-collapse": true,
      "items": _vm.data,
      "responsive": "",
      "tbody-tr-class": _vm.rowClass,
      "hover": "",
      "caption-top": "",
      "show-empty": "",
      "primary-key": "number",
      "striped": "",
      "bordered": "",
      "tbody-transition-props": _vm.transProps
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-0 py-2 text-center"
        }, [_vm._v("Tidak ada data")])];
      },
      proxy: true
    }, {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-left text-info my-2"
        }, [_c('b-icon', {
          staticClass: "align-middle",
          attrs: {
            "icon": "stopwatch",
            "font-scale": "3",
            "variant": "info",
            "animation": "cylon"
          }
        }), _c('p', {
          attrs: {
            "id": "cancel-label"
          }
        }, [_c('strong', [_vm._v("Loading...")])])], 1)];
      },
      proxy: true
    }, {
      key: "bottom-row",
      fn: function fn(data) {
        return _vm._l(data.fields, function (field, i) {
          return _c('td', {
            key: i
          }, [i !== 0 ? _c('span', [_c('strong', [_vm._v(_vm._s(_vm.calculation[field.key]))])]) : _vm._e(), i === 1 ? _c('span', [_vm._v(" Jumlah Data : " + _vm._s(_vm.pagination.totalItems) + " ")]) : _vm._e()]);
        });
      }
    }, {
      key: "head(select)",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          on: {
            "change": _vm.toggleSelectedAll
          }
        })];
      },
      proxy: true
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [data.field.key == 'number' ? _c('div', [_c('p', {
          staticClass: "mb-0 font-weight-bold",
          domProps: {
            "textContent": _vm._s(data.item.number)
          }
        })]) : data.field.key == 'select' && _vm.deleteMode ? _c('div', [_c('b-form-checkbox', {
          model: {
            value: data.item.selected,
            callback: function callback($$v) {
              _vm.$set(data.item, "selected", $$v);
            },
            expression: "data.item.selected"
          }
        })], 1) : data.field.key === 'form_status' ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": _vm.formStatusVariant(data.item.form_status)
          },
          on: {
            "click": function click($event) {
              _vm.approvedLoadingID === data.item.id ? null : _vm.onAprrovedToTrigger(data.item.form_status, data.item.id, data.item);
            }
          }
        }, [_vm.approvedLoading && _vm.approvedLoadingID === data.item.id ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "MousePointerIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(_vm.formStatus(data.item.form_status))
          }
        })], 1)], 1) : data.field.key === 'emergency_action' ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": _vm.formQRCodeVariant(data.item)
          },
          on: {
            "click": function click($event) {
              _vm.emergencyLoadingID === data.item.id ? null : _vm.onInOutToTrigger(data.item.id, data.item);
            }
          }
        }, [_vm.emergencyLoading && _vm.emergencyLoadingID === data.item.id ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "WatchIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(_vm.formQRStatus(data.item))
          }
        })], 1)], 1) : data.field.key === 'action_qrcode' ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": data.item.qrcode ? 'primary' : 'danger'
          },
          on: {
            "click": function click($event) {
              return _vm.showCustomImage(data.item.qrcode, data.item.dm_personal.full_name, data.item.schedule_in);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("QRCode")])], 1)], 1) : data.field.key === 'qrcode_status' ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": _vm.qrcodeStatusVariant(data.item.qrcode_status)
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "WatchIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(data.item.qrcode_status)
          }
        })], 1)], 1) : data.field.key === 'action_form' ? _c('div', [_c('b-dropdown', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "flat-primary",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "to": {
              name: _vm.enableCreateForm,
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "TruckIcon"
          }
        }), _c('span', {
          staticClass: "text-danger align-middle ml-50"
        }, [_vm._v("Lakukan Revisi")])], 1), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-data-history",
            modifiers: {
              "modal-data-history": true
            }
          }]
        }, [_c('feather-icon', {
          staticClass: "text-info",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', {
          staticClass: "text-info align-middle ml-50"
        }, [_vm._v("Histori Revisi")])], 1)], 1)], 1) : _c('div', {
          style: {
            cursor: _vm.isColumnEditable(data) ? 'pointer' : 'text'
          },
          on: {
            "dblclick": function dblclick($event) {
              _vm.forceDisableCrud == true ? '' : _vm.onSelectData(data);
            }
          }
        }, [data.item.onEdit && _vm.isColumnEditable(data) ? _c('div', [_vm.$slots[data.field.columnKey || data.field.key] || _vm.$scopedSlots[data.field.columnKey || data.field.key] ? _vm._t(data.field.key, null, {
          "column": {
            data: data,
            onUpdate: _vm.onUpdate,
            onDelete: _vm.onDelete
          }
        }) : _vm._e(), _c('div', [data.field.input != undefined ? _c('div', [['select', 'date'].includes(data.field.input.type) ? _c('b-form-input', {
          attrs: {
            "readonly": "",
            "value": _vm.beforeSentDate(data),
            "disabled": data.field.input.disabled,
            "state": data.field.columnKey === 'dm_land_vehicle_id' && data.item[data.field.columnKey || data.field.key] === 0 ? false : data.field.input.options && data.field.input.boolean ? true : true
          },
          on: {
            "click": function click($event) {
              return _vm.showSelectModal(data);
            },
            "keyup": [function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              if (!$event.shiftKey) { return null; }
              if ($event.ctrlKey || $event.altKey || $event.metaKey) { return null; }
              return _vm.showSelectModal(data);
            }, function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              if (!$event.ctrlKey) { return null; }
              if ($event.shiftKey || $event.altKey || $event.metaKey) { return null; }
              return _vm.showSelectModal(data);
            }]
          }
        }) : data.field.input.type == 'number' ? _c('b-form-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "number",
            "disabled": data.field.input.disabled,
            "placeholder": "".concat(data.item[data.field.columnKey || data.field.key] || data.field.placeholder),
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                      data.item[data.field.columnKey || data.field.key]\n                    "
          }
        }) : data.field.input.type == 'tag' ? _c('b-form-tags', {
          attrs: {
            "input-id": "tag-".concat(data.uuid),
            "input-attrs": {
              'aria-describedby': 'tags-remove-on-delete-help'
            },
            "separator": ",",
            "placeholder": "1. DosenA, 2. Dosen",
            "remove-on-delete": "",
            "no-add-on-enter": ""
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                      data.item[data.field.columnKey || data.field.key]\n                    "
          }
        }) : data.field.input.type == 'number-append' ? _c('b-input-group', {
          attrs: {
            "append": data.field.input.append
          }
        }, [_c('b-form-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "number",
            "required": "",
            "disabled": data.field.input.disabled,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true,
            "placeholder": "".concat(data.field.placeholder)
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                        data.item[data.field.columnKey || data.field.key]\n                      "
          }
        })], 1) : data.field.input.type == 'currency' ? _c('b-input-group', {
          attrs: {
            "append": data.field.input.append
          }
        }, [_c('currency-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "disabled": data.field.input.disabled,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true,
            "placeholder": "".concat(data.item[data.field.columnKey || data.field.key]),
            "options": {
              currency: 'IDR',
              locale: 'id-ID',
              currencyDisplay: 'hidden',
              autoDecimalDigits: false,
              precision: 2
            }
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                        data.item[data.field.columnKey || data.field.key]\n                      "
          }
        })], 1) : data.field.input.type == 'input-noarea' ? _c('b-form-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "text",
            "disabled": data.field.input.disabled,
            "placeholder": data.field.placeholder,
            "value": data.unformatted,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                      data.item[data.field.columnKey || data.field.key]\n                    "
          }
        }) : _c('b-form-textarea', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "text",
            "disabled": data.field.input.disabled,
            "placeholder": data.field.placeholder,
            "value": data.unformatted,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                      data.item[data.field.columnKey || data.field.key]\n                    "
          }
        })], 1) : _vm._e()])], 2) : _c('div', [data.unformatted !== '' ? _c('div', ['input' in data.field && 'boolean' in data.field.input && data.field.input.boolean === true ? _c('div', [_c('span', {
          domProps: {
            "textContent": _vm._s(data.unformatted == '1' ? '✓' : '✕')
          }
        })]) : 'type' in data.field.input && data.field.input.type === 'date' ? _c('div', [_c('p', {
          staticClass: "mb-0 font-weight-bold",
          domProps: {
            "textContent": _vm._s(_vm.tableDateFormatter(data.unformatted, data.field.input.format))
          }
        })]) : 'type' in data.field.input && data.field.input.type === 'time' ? _c('div', [_c('p', {
          staticClass: "mb-0 font-weight-bold"
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(_vm.tableTimeFormatter(data.unformatted, data.field.input.format))
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": _vm.tableTimeFormatter(data.unformatted, data.field.input.format).toString().includes('PM') ? 'MoonIcon' : 'SunIcon'
          }
        })], 1)]) : data.field.input.type === 'tag' ? _c('div', [_c('b-form-tags', {
          attrs: {
            "input-id": "tag-".concat(data.uuid),
            "input-attrs": {
              'aria-describedby': 'tags-remove-on-delete-help'
            },
            "separator": ",",
            "placeholder": "",
            "disabled": "true",
            "remove-on-delete": "",
            "no-add-on-enter": ""
          },
          model: {
            value: data.unformatted,
            callback: function callback($$v) {
              _vm.$set(data, "unformatted", $$v);
            },
            expression: "data.unformatted"
          }
        })], 1) : data.field.key === 'group.name' ? _c('div', [_c('b-badge', {
          attrs: {
            "href": "#",
            "variant": "info"
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LinkIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(data.unformatted)
          }
        })], 1)], 1) : _c('div', [_c('span', {
          staticClass: "mb-0 font-weight-bold",
          domProps: {
            "textContent": _vm._s(data.unformatted)
          }
        })])]) : _c('span', {
          domProps: {
            "textContent": _vm._s('-')
          }
        })])])];
      }
    }])
  }), _c('b-card-footer', [_c('b-row', {
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "mb-2 mb-sm-0",
    attrs: {
      "sm": "12",
      "md": "2"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.pagination.amountOptions
    },
    model: {
      value: _vm.pagination.selectedAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "selectedAmount", $$v);
      },
      expression: "pagination.selectedAmount"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-center justify-content-sm-end",
    attrs: {
      "sm": "12",
      "md": "2"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0",
    attrs: {
      "total-rows": _vm.pagination.totalItems,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item",
      "per-page": _vm.pagination.selectedAmount
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }