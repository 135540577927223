var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('input', {
    ref: "inputRef",
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.value
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }