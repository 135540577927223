<template>
  <div>
    <input
      ref="inputRef"
      type="text"
      class="form-control"
      :value="value"
    >
  </div>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    }, // Vue 2: value
    options: Object,
  },
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options)
    return { inputRef }
  },
}
</script>
